import { primary45 } from "./utils/colors";

export function Header() {
  const styles = {
    container: {
      backgroundColor: primary45,
      color: '#FFF',
      padding: 12,
      marginTop: '45px',
      fontWeight: 600,
    }
  }
  return <div></div>
}
