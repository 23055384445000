export const primary = '#212936';
export const primary2 = '#212936';
export const primary3 = '#212936';
export const primary35 = '#212936';
export const primary4 = '#212936';
export const primary45 = '#212936';
export const primary46 = '#6778cb';

export const primary15 = 'rgb(241 249 255)';

export const primary5 = '#3881ad';
export const primary6 = '#132b3a';

// export const primary = '#666';
// export const primary2 = '#EEE';
// export const primary3 = '#CCC';
// export const primary4 = '#AAA';
// export const primary5 = '#888';
// export const primary6 = '#333';

export const primary16 = '#212936';

export const errorColor = '#ef6565';
export const lightErrorColor = '#ef9c9c';
export const goodColor = '#53c171';

export const cleanBorder = '1px solid #212936';
export const lightBorder = 'hsl(203 51% 80% / 1)';

// #212936
