import { BigButton } from "./BigButton";
import { primary45 } from "../utils/colors";

export default function PagingControl({ totalPages, pageNum, setPageNum }) {
  const styles = {
    container: {
      marginTop: 8,
      marginBottom: 8,
    },
    inlineFlex: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    pageInfo: {
      padding: 8,
      color: '#000',
      fontSize: 14,
      borderTop: "1px solid #21293629",
      borderBottom: "1px solid #21293629",
    }
  }
  return (
    <div style={styles.container}>
      <div style={styles.inlineFlex}>
        <BigButton
          title={"<"}
          onClick={() => setPageNum(pageNum - 1)}
          disabled={pageNum - 1 === -1}
          style={{ borderColor: '#21293629', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        />
        <div style={styles.pageInfo}>
          Page: {pageNum + 1}/{totalPages}
        </div>
        <BigButton
          title={">"}
          onClick={() => setPageNum(pageNum + 1)}
          disabled={pageNum + 1 > totalPages - 1}
          style={{ borderColor: '#21293629', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        />
      </div>
    </div>
  );
}
