import "./App.css";
import { useEffect, useRef, useState } from "react";
import Drop from "./Drop";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument, rgb } from "pdf-lib";
import { blobToURL } from "./utils/Utils";
import PagingControl from "./components/PagingControl";
import { AddSigDialog } from "./components/AddSigDialog";
import { Header } from "./Header";
import { BigButton } from "./components/BigButton";
import DraggableSignature from "./components/DraggableSignature";
import DraggableText from "./components/DraggableText";
import dayjs from "dayjs";
import axios from 'axios'
import { Popover, Steps, message, Popconfirm } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function downloadURI(uri, name) {
	var link = document.createElement("a");
	link.download = name;
	link.href = uri;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

function App() {
	const [signer, setSigner] = useState('');

	const queryParams = new URLSearchParams(window.location.search);
	const fileId = queryParams.get('file');
	const baseUrl = queryParams.get('baseurl');
	const baseUrl2 = queryParams.get('baseurl2');
	const id = queryParams.get('id');
	const email = queryParams.get('email');
	const token = queryParams.get('token');
	const domain = queryParams.get('domain');
	const sign_status = queryParams.get('sign_status');
	const signer1 = queryParams.get('signer1');
	const signer2 = queryParams.get('signer2');
	const [step, setStep] = useState(1);

	const styles = {
		container: {
			maxWidth: 900,
			margin: "0 auto",
			marginTop: '10px',
			paddingTop: signer ? '210px' : '50px'
		},
		sigBlock: {
			display: "inline-block",
			border: "1px solid #000",
		},
		documentBlock: {
			maxWidth: 800,
			margin: "20px auto",
			marginTop: "0px",
			border: "1px solid #999",

		},
		controls: {
			margin: "0 auto",
			// position: 'fixed',
			top: '0px',
			zIndex: 9999,
			left: 0,
			margin: 'auto',
			// width: '100%',
			textAlign: 'center',
			paddingBottom: '10px',
			paddingTop: '10px',
			// background: '#ffffffb5'
		},

		controls1: {
			margin: "0 auto",
			// position: 'fixed',
			top: '0px',
			zIndex: 9999,
			right: 0,
			margin: 'auto',
			// width: '100%',
			textAlign: 'center',
			paddingBottom: '10px',
			paddingTop: '10px',
			// background: '#ffffffb5'
		}
	};
	const [pdf, setPdf] = useState(null);
	const [origin, setOrigin] = useState(null);
	const [autoDate, setAutoDate] = useState(true);
	const [signatureURL, setSignatureURL] = useState(null);
	const [position, setPosition] = useState(null);
	const [signatureDialogVisible, setSignatureDialogVisible] = useState(false);
	const [textInputVisible, setTextInputVisible] = useState(false);
	const [pageNum, setPageNum] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [pageDetails, setPageDetails] = useState(null);
	const documentRef = useRef(null);
	const [userdata1, setUserdata1] = useState({})
	const [userdata2, setUserdata2] = useState({})

	const [confirm1, setConfirm1] = useState(false);
	const [confirm2, setConfirm2] = useState(false);

	useEffect(async () => {
		try {
			await axios.post(`${baseUrl}/api/getsigners`, { sign1: signer1, sign2: signer2 })
				.then(response => {
					const result = response.data.data;
					for (let i = 0; i < result.length; i++) {
						if (result[i].email === signer1) {
							setUserdata1(result[i]);
						}
						if (result[i].email === signer2) {
							setUserdata2(result[i]);
						}
					}
				})
		} catch (error) {
			console.log(error)
		}
	}, [])

	const request = async (...data) => {
		try {
			const response = await fetch(...data);
			return response;
		} catch (error) {
			// Handle any network or fetch related errors here
			console.error("Request error:", error);
			throw error; // Optionally, rethrow the error to propagate it further if needed
		}
	};

	function getCurrentTime() {
		var currentDate = new Date();

		var dd = String(currentDate.getDate()).padStart(2, '0');
		var mm = String(currentDate.getMonth() + 1).padStart(2, '0'); // January is 0!
		var yyyy = currentDate.getFullYear();

		var hh = String(currentDate.getHours()).padStart(2, '0');
		var min = String(currentDate.getMinutes()).padStart(2, '0');
		var ss = String(currentDate.getSeconds()).padStart(2, '0');

		return mm + '/' + dd + '/' + yyyy + ' ' + hh + ':' + min + ':' + ss;
	}

	const updatePdf = async () => {
		try {
			message.config({
				top: 100, // The distance from the top of the page
			});

			if (!confirm1 || !confirm2) {
				message.error('Please add a signature and date to sign');
				return;
			}

			setStep(4)

			const resultString1 = pdf.replace('data:application/pdf;base64,', "");
			const resultString2 = resultString1.replace('data:application/octet-stream;base64,', "");

			await axios.post(`${baseUrl}/api/updatesign1`, { file: resultString2, id: id, email: email, sign_status: sign_status });
			message.success('Successfully updated!');
			setSigner('')

			const sender1 = userdata1.fname ? `${userdata1.fname} ${userdata1.lname}` : signer1;
			const sender2 = userdata2.fname ? `${userdata2.fname} ${userdata2.lname}` : signer2;
			// notification
			if (sign_status === '1') {
				// await axios.post(`${baseUrl}/api/updateprojectbystatus`, { id: id, email: email, status: 4 });
				const data = {
					project_id: null,
					title: "Project signed",
					category: 2,
					type: "role_changes",
					message: `${sender1} has signed the agreement and is requesting your signature.`,
					project_link: `/active-projects/${id}?type=tosign`,
					assigned_useremail: sign_status === '1' ? signer2 : signer1,
					notification: true,
					showingEmail: true,
					domain: domain,
					buttontext: 'Sign now',
					template: 1,
					message_type: 1,
				}

				await request(baseUrl2, {
					method: "POST",
					headers: {
						authorization: `Bearer ${token}`,
						accept: "application/json",
					},
					body: JSON.stringify(data),
				});
			}

			if (sign_status === '2') {
				const data = {
					project_id: null,
					title: "Sign completed",
					category: 2,
					type: "role_changes",
					message: `Congratulations! Your project is complete!`,
					project_link: `/active-projects/${id}`,
					assigned_useremail: signer1,
					notification: true,
					showingEmail: true,
					buttontext: 'View completed project',
					domain: domain,
					template: 1,
					message_type: 1,
				}

				await request(baseUrl2, {
					method: "POST",
					headers: {
						authorization: `Bearer ${token}`,
						accept: "application/json",
					},
					body: JSON.stringify(data),
				});

				setTimeout(async () => {
					const data2 = {
						project_id: null,
						title: "Sign completed",
						category: 2,
						type: "role_changes",
						message: `Congratulations! Your project is complete!`,
						project_link: `/active-projects/${id}`,
						assigned_useremail: signer2,
						notification: true,
						showingEmail: true,
						domain: domain,
						buttontext: 'View completed project',
						template: 1,
						message_type: 1,
					}

					await request(baseUrl2, {
						method: "POST",
						headers: {
							authorization: `Bearer ${token}`,
							accept: "application/json",
						},
						body: JSON.stringify(data2),
					});
				}, 10000);
			}

			console.log('PDF file uploaded successfully');
		} catch (error) {
			console.error('Error uploading PDF:', error);
		}
	};

	useEffect(() => {
		setSigner(queryParams.get('signer'));
		// if (queryParams.get('signer')) {
		// 	message.config({
		// 		duration: 10,
		// 		maxCount: 3,
		// 		style: {
		// 			zIndex: 99999,
		// 		},
		// 	});
		// 	const content = `
		// 	By signing this agreement, I acknowledge that the templates provided by Song Safety are not legal advice. <br />
		// 	I understand that I am responsible for reviewing and customizing the agreement as needed and consulting with a legal professional.  <br />
		// 	Song Safety is not liable for any errors or omissions. [<a target="_blank" style="color: #69B1FF" href="http://143.198.79.229:3000/terms-and-conditions-and-privacy-policy">More Info</a>]
		// 	`;

		// 	message.success({
		// 		content: <span dangerouslySetInnerHTML={{ __html: content }} />,
		// 	});
		// }
		const fetchPdf = async () => {
			try {
				console.log(`${baseUrl}/${fileId}`)
				const response = await fetch(`${baseUrl}/${fileId}`);
				const blob = await response.blob();
				const reader = new FileReader();

				reader.onload = () => {
					const base64Data = btoa(reader.result);
					setPdf(`data:application/pdf;base64,${base64Data}`)
					setOrigin(`data:application/pdf;base64,${base64Data}`)
				};

				reader.readAsBinaryString(blob);
			} catch (error) {
				console.error('Error fetching PDF:', error);
			}
		};

		fetchPdf();

		const receiveMessage = (event) => {
			if (event.origin !== 'PARENT_WEBSITE_ORIGIN') return; // Replace with parent website origin
			const data = event.data;
			console.log('Received data in iframe:', data);
		};

		window.addEventListener('message', receiveMessage);

		return () => {
			window.removeEventListener('message', receiveMessage);
		};

	}, []);


	const [scrollY, setScrollY] = useState(0);

	const handleScroll = () => {
		setScrollY(window.scrollY);
	};

	useEffect(() => {
		setScrollY(window.scrollY); // Initialize scrollY on mount
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div>
			<Header />

			{signer && (
				<div style={{ position: 'fixed', top: '0px', right: '10px', textAlign: 'right', marginBottom: '10px', marginRight: '120px', zIndex: 99999 }}>
					<Popconfirm
						icon={
							<svg style={{ marginRight: '10px', marginTop: '2px' }} width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M13.4194 25.6666C14.8883 25.6685 16.343 25.3676 17.7 24.7811C19.057 24.1947 20.2896 23.3343 21.327 22.2494C22.3669 21.1672 23.1916 19.8814 23.7537 18.4656C24.3159 17.0499 24.6043 15.5323 24.6025 13.9999C24.6043 12.4676 24.3159 10.9499 23.7537 9.53421C23.1916 8.1185 22.3669 6.83261 21.327 5.75043C20.2896 4.66552 19.057 3.80514 17.7 3.21871C16.343 2.63228 14.8883 2.33137 13.4194 2.33326C11.9506 2.33137 10.4958 2.63228 9.1388 3.21871C7.78177 3.80514 6.54919 4.66552 5.51186 5.75043C4.47192 6.83261 3.6472 8.1185 3.08508 9.53421C2.52297 10.9499 2.23453 12.4676 2.23634 13.9999C2.23453 15.5323 2.52297 17.0499 3.08508 18.4656C3.6472 19.8814 4.47192 21.1672 5.51186 22.2494C6.54919 23.3343 7.78177 24.1947 9.1388 24.7811C10.4958 25.3676 11.9506 25.6685 13.4194 25.6666Z" stroke="#3265E2" stroke-width="2" stroke-linejoin="round" />
								<path fill-rule="evenodd" clip-rule="evenodd" d="M13.4194 21.5832C13.7901 21.5832 14.1457 21.4295 14.4078 21.156C14.67 20.8825 14.8173 20.5116 14.8173 20.1248C14.8173 19.7381 14.67 19.3671 14.4078 19.0936C14.1457 18.8201 13.7901 18.6665 13.4194 18.6665C13.0486 18.6665 12.6931 18.8201 12.4309 19.0936C12.1688 19.3671 12.0215 19.7381 12.0215 20.1248C12.0215 20.5116 12.1688 20.8825 12.4309 21.156C12.6931 21.4295 13.0486 21.5832 13.4194 21.5832Z" fill="#3265E2" />
								<path d="M13.4189 7V16.3333" stroke="#3265E2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						}
						placement="leftTop"
						title="Are you sure? This will erase all current signature details."
						onConfirm={() => {
							setTextInputVisible(false);
							setSignatureDialogVisible(false);
							setSignatureURL(null);
							setPdf(origin);
							setTotalPages(0);
							setPageNum(0);
							setPageDetails(null);
							setStep(1);
						}}
						onCancel={() => console.log('Cancelled')}
						okText={<span style={{ padding: '12px, 20px, 12px, 20px' }}>Reset Details</span>}
						cancelText="No"
					>
						<a style={{
							display: "inline-flex",
							alignItems: "center",
							justifyContent: "center",
							color: "rgb(33, 41, 54)",
							borderRadius: "6px",
							padding: "5px 13px",
							fontSize: "15px",
							border: "1px solid rgb(199, 199, 204)",
							cursor: "pointer",
							userSelect: "none",
							boxSizing: "border-box",
							marginRight: "8px",
							border: '1px solid #212936'
						}}>
							Reset
						</a>
					</Popconfirm>
				</div>
			)}

			{pdf && signer && <div className="top-space" style={{ width: '100%', height: '120px', background: '#fff', position: 'fixed', top: '0px', zIndex: 99 }}></div>}

			{pdf && signer && (
				<div style={{  backgroundColor: '#fff', display: 'flex', gap: '20px', padding: '30px 20px', position: 'fixed', width: 'calc(100vw - 50px)', margin: 'auto', zIndex: 999, boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.1)' }}>
					<Steps
						current={step}
						// progressDot={customDot}
						items={[
							{
								title: 'Step1',
								description:
									<div>
										<div style={{ color: "#6F6F6F", fontSize: '18px' }}>Add Signature</div>
										<div>
											{step === 1 && <div style={{ color: '#2762E9', fontSize: '15px' }}>In process</div>}
											{step > 1 && <div style={{ color: '#1CA289', fontSize: '15px' }}>Completed</div>}
										</div>
										<div>
											<div style={styles.controls}>
												{!signatureURL && (
													<BigButton
														inverted={true}
														disabled={step === 1 ? false : true}
														marginRight={8}
														title={"Add Signature"}
														onClick={() => setSignatureDialogVisible(true)}
													/>
												)}
											</div>
										</div>
									</div>,
								icon:
									<div>
										{step === 1 &&
											<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#3265E2" />
												<path d="M9.05321 12.3867C8.91988 12.3867 8.79321 12.3334 8.69988 12.2401L6.81321 10.3534C6.61988 10.1601 6.61988 9.84007 6.81321 9.64673C7.00655 9.4534 7.32655 9.4534 7.51988 9.64673L9.05321 11.1801L12.4799 7.7534C12.6732 7.56007 12.9932 7.56007 13.1865 7.7534C13.3799 7.94673 13.3799 8.26673 13.1865 8.46006L9.40655 12.2401C9.31321 12.3334 9.18655 12.3867 9.05321 12.3867Z" fill="#3265E2" />
												<circle cx="10" cy="10" r="4" fill="#3265E2" />
											</svg>
										}
										{step > 1 &&
											<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect width="20" height="20" rx="10" fill="#3265E2" />
												<path d="M9.05321 12.3867C8.91988 12.3867 8.79321 12.3334 8.69988 12.2401L6.81321 10.3534C6.61988 10.1601 6.61988 9.84007 6.81321 9.64673C7.00655 9.4534 7.32655 9.4534 7.51988 9.64673L9.05321 11.1801L12.4799 7.7534C12.6732 7.56007 12.9932 7.56007 13.1865 7.7534C13.3799 7.94673 13.3799 8.26673 13.1865 8.46006L9.40655 12.2401C9.31321 12.3334 9.18655 12.3867 9.05321 12.3867Z" fill="white" />
											</svg>
										}
									</div>
							},

							{
								title: 'Step2',
								description:
									<div>
										<div style={{ color: "#6F6F6F", fontSize: '18px', fontWeight: '700' }}>Add Date</div>
										<div>
											{step < 2 && <div style={{ color: '#2762E9', fontSize: '15px' }}>Pending</div>}
											{step === 2 && <div style={{ color: '#2762E9', fontSize: '15px' }}>In process</div>}
											{step > 2 && <div style={{ color: '#1CA289', fontSize: '15px' }}>Completed</div>}
										</div>
										<div>
											<div style={styles.controls}>
												<BigButton
													inverted={true}
													disabled={step === 2 ? false : true}
													marginRight={8}
													title={"Add Date"}
													onClick={() => setTextInputVisible("date")}
												/>
											</div>
										</div>
									</div>,
								icon:
									<div>
										{step === 1 &&
											<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect width="20" height="20" rx="10" fill="#B8BABE" />
												<path d="M9.05321 12.3867C8.91988 12.3867 8.79321 12.3334 8.69988 12.2401L6.81321 10.3534C6.61988 10.1601 6.61988 9.84007 6.81321 9.64673C7.00655 9.4534 7.32655 9.4534 7.51988 9.64673L9.05321 11.1801L12.4799 7.7534C12.6732 7.56007 12.9932 7.56007 13.1865 7.7534C13.3799 7.94673 13.3799 8.26673 13.1865 8.46006L9.40655 12.2401C9.31321 12.3334 9.18655 12.3867 9.05321 12.3867Z" fill="white" />
											</svg>

										}
										{step === 2 &&
											<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#3265E2" />
												<path d="M9.05321 12.3867C8.91988 12.3867 8.79321 12.3334 8.69988 12.2401L6.81321 10.3534C6.61988 10.1601 6.61988 9.84007 6.81321 9.64673C7.00655 9.4534 7.32655 9.4534 7.51988 9.64673L9.05321 11.1801L12.4799 7.7534C12.6732 7.56007 12.9932 7.56007 13.1865 7.7534C13.3799 7.94673 13.3799 8.26673 13.1865 8.46006L9.40655 12.2401C9.31321 12.3334 9.18655 12.3867 9.05321 12.3867Z" fill="#3265E2" />
												<circle cx="10" cy="10" r="4" fill="#3265E2" />
											</svg>
										}
										{step >= 3 &&
											<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect width="20" height="20" rx="10" fill="#3265E2" />
												<path d="M9.05321 12.3867C8.91988 12.3867 8.79321 12.3334 8.69988 12.2401L6.81321 10.3534C6.61988 10.1601 6.61988 9.84007 6.81321 9.64673C7.00655 9.4534 7.32655 9.4534 7.51988 9.64673L9.05321 11.1801L12.4799 7.7534C12.6732 7.56007 12.9932 7.56007 13.1865 7.7534C13.3799 7.94673 13.3799 8.26673 13.1865 8.46006L9.40655 12.2401C9.31321 12.3334 9.18655 12.3867 9.05321 12.3867Z" fill="white" />
											</svg>
										}
									</div>
							},
							{
								title: 'Step3',
								description:
									<div>
										<div style={{ color: "#6F6F6F", fontSize: '18px', fontWeight: '700' }}>Sign</div>
										<div>
											{step < 3 && <div style={{ color: '#2762E9', fontSize: '15px' }}>Pending</div>}
											{step === 3 && <div style={{ color: '#2762E9', fontSize: '15px' }}>In process</div>}
											{step > 3 && <div style={{ color: '#1CA289', fontSize: '15px' }}>Completed</div>}
										</div>
										<div>
											<div style={styles.controls}>
												<BigButton
													inverted={true}
													disabled={step === 3 ? false : true}
													style={{ float: 'right' }}
													marginRight={8}
													title={"Sign"}
													onClick={() => updatePdf()}
												/>
											</div>
										</div>
									</div>,
								icon:
									<div>
										{step < 3 &&
											<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect width="20" height="20" rx="10" fill="#B8BABE" />
												<path d="M9.05321 12.3867C8.91988 12.3867 8.79321 12.3334 8.69988 12.2401L6.81321 10.3534C6.61988 10.1601 6.61988 9.84007 6.81321 9.64673C7.00655 9.4534 7.32655 9.4534 7.51988 9.64673L9.05321 11.1801L12.4799 7.7534C12.6732 7.56007 12.9932 7.56007 13.1865 7.7534C13.3799 7.94673 13.3799 8.26673 13.1865 8.46006L9.40655 12.2401C9.31321 12.3334 9.18655 12.3867 9.05321 12.3867Z" fill="white" />
											</svg>

										}
										{step === 3 &&
											<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#3265E2" />
												<path d="M9.05321 12.3867C8.91988 12.3867 8.79321 12.3334 8.69988 12.2401L6.81321 10.3534C6.61988 10.1601 6.61988 9.84007 6.81321 9.64673C7.00655 9.4534 7.32655 9.4534 7.51988 9.64673L9.05321 11.1801L12.4799 7.7534C12.6732 7.56007 12.9932 7.56007 13.1865 7.7534C13.3799 7.94673 13.3799 8.26673 13.1865 8.46006L9.40655 12.2401C9.31321 12.3334 9.18655 12.3867 9.05321 12.3867Z" fill="#3265E2" />
												<circle cx="10" cy="10" r="4" fill="#3265E2" />
											</svg>
										}
										{step > 3 &&
											<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect width="20" height="20" rx="10" fill="#3265E2" />
												<path d="M9.05321 12.3867C8.91988 12.3867 8.79321 12.3334 8.69988 12.2401L6.81321 10.3534C6.61988 10.1601 6.61988 9.84007 6.81321 9.64673C7.00655 9.4534 7.32655 9.4534 7.51988 9.64673L9.05321 11.1801L12.4799 7.7534C12.6732 7.56007 12.9932 7.56007 13.1865 7.7534C13.3799 7.94673 13.3799 8.26673 13.1865 8.46006L9.40655 12.2401C9.31321 12.3334 9.18655 12.3867 9.05321 12.3867Z" fill="white" />
											</svg>
										}
									</div>
							},
						]}
					/>
				</div>
			)}

			<div style={styles.container}>
				{signatureDialogVisible ? (
					<div>
						<AddSigDialog
							autoDate={autoDate}
							setAutoDate={setAutoDate}
							onClose={() => setSignatureDialogVisible(false)}
							onConfirm={(url) => {
								setSignatureURL(url);
								setSignatureDialogVisible(false);
								setConfirm1(true);
								setStep(2);
							}}
						/>
					</div>
				) : null}

				{pdf ? (
					<div style={{ overflow: 'visible' }}>
						{/* {signer &&
							<div>
								<div style={styles.controls}>
									{!signatureURL ? (
										<BigButton
											marginRight={8}
											title={"Add Signature"}
											onClick={() => setSignatureDialogVisible(true)}
										/>
									) : null}

									<BigButton
										marginRight={8}
										title={"Add Date"}
										onClick={() => setTextInputVisible("date")}
									/>

									<BigButton
										marginRight={8}
										title={"Reset"}
										onClick={() => {
											setTextInputVisible(false);
											setSignatureDialogVisible(false);
											setSignatureURL(null);
											setPdf(origin);
											setTotalPages(0);
											setPageNum(0);
											setPageDetails(null);
										}}
									/>
								</div>
								<div style={styles.controls1}>
									{pdf ? (
										<BigButton
											style={{ float: 'right' }}
											marginRight={8}
											inverted={true}
											title={"Sign"}
											onClick={() => updatePdf()}
										/>
									) : null}
								</div>
							</div>
						} */}
						<div ref={documentRef} style={styles.documentBlock}>
							{textInputVisible ? (
								<DraggableText
									initialText={
										textInputVisible === "date"
											? `${new Date().toLocaleDateString()}`
											: null
									}

									onCancel={() => setTextInputVisible(false)}
									onEnd={setPosition}
									onSet={async (text) => {
										console.log(222, position)
										const { originalHeight, originalWidth } = pageDetails;
										const scale = originalWidth / documentRef.current.clientWidth;



										let addtional = 0;
										console.log(22222, position.y)

										if (position.y < 240) {
											addtional = 2
										}
										else if (position.y >= 240 && position.y < 400) {
											addtional = -30;
										}
										else if (position.y >= 400 && position.y < 600) {
											addtional = -10;
										}
										else if (position.y >= 500 && position.y < 600) {
											addtional = 0;
										}
										else if (position.y >= 600 && position.y < 700) {
											addtional = 0;
										}
										else if (position.y >= 700 && position.y < 800) {
											addtional = 10;
										}
										else if (position.y >= 800 && position.y < 900) {
											addtional = 15;
										}
										else if (position.y >= 900 && position.y < 1000) {
											addtional = 20;
										}

										if (scrollY > 0) {
											addtional = addtional + scrollY + 40;
										}

										console.log(111, addtional)

										const y =
											documentRef.current.clientHeight -
											(position.y +
												(12 * scale) -
												position.offsetY -
												documentRef.current.offsetTop + addtional);

										const x =
											position.x -
											203 -
											position.offsetX -
											documentRef.current.offsetLeft;


										// new XY in relation to actual document size
										const newY =
											(y * originalHeight) / documentRef.current.clientHeight;
										const newX =
											(x * originalWidth) / documentRef.current.clientWidth;

										const pdfDoc = await PDFDocument.load(pdf);

										const pages = pdfDoc.getPages();
										const firstPage = pages[pageNum];

										firstPage.drawText(text, {
											x: newX,
											y: newY,
											size: 20 * scale,
										});

										const pdfBytes = await pdfDoc.save();
										const blob = new Blob([new Uint8Array(pdfBytes)]);

										const URL = await blobToURL(blob);
										// const { originalHeight, originalWidth } = pageDetails;
										// const scale = originalWidth / documentRef.current.clientWidth;
										// const y =
										// 	documentRef.current.clientHeight -
										// 	(position.y +
										// 		(12 * scale) -
										// 		position.offsetY - documentRef.current.offsetTop + scrollY * scale);
										// const x =
										// 	position.x -
										// 	166 -
										// 	position.offsetX -
										// 	documentRef.current.offsetLeft;

										if (textInputVisible === "date") {
											setConfirm2(true);
										}

										// // new XY in relation to actual document size
										// const newY =
										// 	(y * originalHeight) / documentRef.current.clientHeight;
										// const newX =
										// 	(x * originalWidth) / documentRef.current.clientWidth;

										// const pdfDoc = await PDFDocument.load(pdf);

										// const pages = pdfDoc.getPages();
										// const firstPage = pages[pageNum];

										// firstPage.drawText(text, {
										// 	x: newX,
										// 	y: newY,
										// 	size: 20 * scale,
										// });

										// const pdfBytes = await pdfDoc.save();
										// const blob = new Blob([new Uint8Array(pdfBytes)]);

										// const URL = await blobToURL(blob);
										setPdf(URL);
										setPosition(null);
										setTextInputVisible(false);
										setStep(3);
									}}
								/>
							) : null}
							{signatureURL ? (
								<DraggableSignature
									url={signatureURL}
									onCancel={() => {
										setSignatureURL(null);
									}}
									onSet={async () => {
										const { originalHeight, originalWidth } = pageDetails;
										const scale = originalWidth / documentRef.current.clientWidth;
										const scaleY = originalHeight / documentRef.current.clientHeight;

										let addtional = 0;

										console.log(22222, position.y)

										if (position.y < 240) {
											addtional = 2
										}
										else if (position.y >= 240 && position.y < 400) {
											addtional = 10;
										}
										else if (position.y >= 400 && position.y < 600) {
											addtional = 23;
										}
										else if (position.y >= 500 && position.y < 600) {
											addtional = 30;
										}
										else if (position.y >= 600 && position.y < 700) {
											addtional = 40;
										}
										else if (position.y >= 700 && position.y < 800) {
											addtional = 50;
										}
										else if (position.y >= 800 && position.y < 900) {
											addtional = 55;
										}
										else if (position.y >= 900 && position.y < 1000) {
											addtional = 63;
										}

										if (scrollY > 0) {
											addtional = addtional + scrollY + 38;
										}

										console.log(555, addtional)

										const y =
											documentRef.current.clientHeight -
											(position.y +
												(12 * scale) -
												position.offsetY -
												documentRef.current.offsetTop + addtional);
										const x =
											position.x - 150 - position.offsetX - documentRef.current.offsetLeft;

										// new XY in relation to actual document size
										const newY =
											(y * originalHeight) / documentRef.current.clientHeight;
										const newX =
											(x * originalWidth) / documentRef.current.clientWidth;

										const pdfDoc = await PDFDocument.load(pdf);

										const pages = pdfDoc.getPages();
										const firstPage = pages[pageNum];

										const pngImage = await pdfDoc.embedPng(signatureURL);
										const pngDims = pngImage.scale(scale * .3);

										firstPage.drawImage(pngImage, {
											x: newX,
											y: newY,
											width: pngDims.width,
											height: pngDims.height,
										});

										if (autoDate) {
											firstPage.drawText(
												`Signed ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
												{
													x: newX,
													y: newY - 10,
													size: 14 * scale,
													color: rgb(0.074, 0.545, 0.262),
												}
											);
										}

										const pdfBytes = await pdfDoc.save();
										const blob = new Blob([new Uint8Array(pdfBytes)]);

										const URL = await blobToURL(blob);
										setPdf(URL);
										setPosition(null);
										setSignatureURL(null);
									}}
									onEnd={setPosition}
								// onSet={async () => {
								// 	const { originalHeight, originalWidth } = pageDetails;
								// 	const scale = originalWidth / documentRef.current.clientWidth;

								// 	const y =
								// 		documentRef.current.clientHeight -
								// 		(position.y -
								// 			position.offsetY +
								// 			16 -
								// 			documentRef.current.offsetTop + scrollY);
								// 	const x =
								// 		position.x -
								// 		146 -
								// 		position.offsetX -
								// 		documentRef.current.offsetLeft;

								// 	// new XY in relation to actual document size
								// 	const newY =
								// 		(y * originalHeight) / documentRef.current.clientHeight;
								// 	const newX =
								// 		(x * originalWidth) / documentRef.current.clientWidth;

								// 	const pdfDoc = await PDFDocument.load(pdf);

								// 	const pages = pdfDoc.getPages();
								// 	const firstPage = pages[pageNum];

								// 	const pngImage = await pdfDoc.embedPng(signatureURL);
								// 	const pngDims = pngImage.scale(scale * .3);

								// 	firstPage.drawImage(pngImage, {
								// 		x: newX,
								// 		y: newY,
								// 		width: pngDims.width,
								// 		height: pngDims.height,
								// 	});

								// 	if (autoDate) {
								// 		firstPage.drawText(
								// 			// `Signed ${dayjs().format(
								// 			// 	"M/d/YYYY HH:mm:ss ZZ"
								// 			// )}`,
								// 			`Signed ${getCurrentTime()}`,
								// 			{
								// 				x: newX,
								// 				y: newY - 10,
								// 				size: 14 * scale,
								// 				color: rgb(0.074, 0.545, 0.262),
								// 			}
								// 		);
								// 	}

								// 	const pdfBytes = await pdfDoc.save();
								// 	const blob = new Blob([new Uint8Array(pdfBytes)]);

								// 	const URL = await blobToURL(blob);
								// 	setPdf(URL);
								// 	setPosition(null);
								// 	setSignatureURL(null);
								// }}
								// onEnd={setPosition}
								/>
							) : null}

							<PagingControl
								pageNum={pageNum}
								setPageNum={setPageNum}
								totalPages={totalPages}
							/>

							<Document
								file={pdf}
								onLoadSuccess={(data) => {
									setTotalPages(data.numPages);
								}}
							>
								<Page
									style={{ marginTop: '47px', userSelect: 'none' }}
									pageNumber={pageNum + 1}
									width={800}
									height={1200}
									marginTop={20}
									onLoadSuccess={(data) => {
										setPageDetails(data);
									}}
								/>
							</Document>


							<PagingControl
								pageNum={pageNum}
								setPageNum={setPageNum}
								totalPages={totalPages}
							/>
						</div>
					</div>
				) : null}
			</div>
		</div >
	);
}

export default App;
